import axios from "axios";

//http://secura.multillantas.com
//https://test.multillantas.com
const apiBaseUrlSecura =
  "https://test.multillantas.com/AppVentas/fwd_entryPoint.php"; //"/AppVentas/fwd_entryPoint.php";
const securaToken =
  "8850770ECA03004139B21CDEDC37AE9AFB4FC90553C35A615F9455C975BFAE2B";

const osData = async (API_Token, id, os) => {
  try {
    const response = await axios.post(
      `${apiBaseUrlSecura}?endpoint=api/index&action=osdetails`,
      {
        id,
        os,
      },
      {
        headers: { "X-Auth-Token": API_Token, "X-Secura-Token": securaToken },
      }
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if (error.response.status === 401) {
        // Handle token expiration
        throw new Error("Token expired");
      } else {
        // Handle other HTTP errors
        throw new Error(`HTTP error: ${error.response.status}`);
      }
    } else if (error.request) {
      // The request was made but no response was received
      throw new Error("No response received");
    } else {
      // Something happened in setting up the request that triggered an Error
      throw new Error(`Error: ${error.message}`);
    }
  }
};

export default osData;
